import React, { useState, useContext, useEffect } from "react";
import Link from "next/link";
import Container from "../Container.js";
import LoginModal from "./LoginModal.js";
import SignupModal from "./SignupModal.js";
import AuthContext from "../../contexts/AuthContext";
import { hostEventUrl, loginUrl } from "../../config/config";
import { Auth } from "aws-amplify";
import { toast } from "react-toastify";
import ForgotPasswordModal from "./ForgotPasswordModal.js";
import NewPassowrdModal from "./NewPasswordModal.js";
import MobileNavbar from "./MobileNavbar";
import Cookies from "js-cookie";
import { removeCognitoFromLocalStorage } from "../../common/Functions";
import ProfileCard from "./ProfileCard.js";
import { successToaster } from "../../helpers/utils.js";
import { useRouter } from "next/router";
import Loading from "../Loading.js";
import eventContext from "../../contexts/EventContext";
import SwitchApplications from "./SwitchApplications.js";
toast.configure();

const UnauthenticatedNavigation = ({
  eventDetails,
  user,
  newUserLogin,
  logoWhite,
  color,
  disableLogo = false,
  hideProfile = false,
  mobileNavbar = true,
  hideLinks = false,
  eventPage = false,
}) => {

  const authContext = useContext(AuthContext);
  const [loginModalOpen, setloginModalOpen] = useState(
    newUserLogin && !authContext.signedIn ? true : false
  );
  const [signupModalOpen, setSignupModalOpen] = useState(false);
  const [forgotPasswordModalOpen, setForgotPasswordModalOpen] = useState(false);
  const [newPasswordModal, setNewPasswordModal] = useState(false);
  const [email, setEmail] = useState("");
  const router = useRouter();
  const EventContext = useContext(eventContext);
  const imgLogoWidth = EventContext?.eventData?.navbar_icon ? 'auto' : 188


  async function handleLogout() {
    try {
      let message = "Logged Out Successfully";
      successToaster(message);
      authContext.setAuth({ attributes: {} }, false);
      let cookies = Cookies.get();
      Object.keys(cookies).map((key) => {
        if (key.includes("CognitoIdentityServiceProvider")) Cookies.remove(key);
      });
      removeCognitoFromLocalStorage();
      Cookies.remove("authToken", { sameSite: "strict" });
      Auth.signOut();
      if (router.pathname === "/profile" || router.pathname === "/bookings") {
        router.replace("/", undefined, { shallow: false });
      }
      // router.replace('/', undefined, { shallow: false })
    } catch (err) {
      console.error(err.message);
    }
  }
  return (
    <>
      {/* Desktop Navbar */}
      <Container className="app-header pb-4 pt-3">
        <div className="row app-header">
        {EventContext?.eventData?.navbar_icon ? 
          <div style={{ zIndex: 100 }} className="col-8 col-md-4 ps-1">
            <img
              loading="lazy"
              src={EventContext.eventData.navbar_icon}
              className="d-inline-block link-text"
              alt="Custom Icon"
              width={`${imgLogoWidth}`}
              height={50}
            />
          </div>
          : <div style={{ zIndex: 100 }} className="col-8 col-md-4 ps-1">
            <img
              loading="lazy"
              src=""
              className="d-inline-block link-text"
            />
          </div>
        }
          <div
            style={{ zIndex: 100 }}
            className="col-4 col-md-8 d-flex align-items-center justify-content-end"
          >
            {!hideLinks && (
              <div className="app-menu d-none d-md-flex">
                {/* <Link href="/events">
								<a className="app-menu-item mx-lg-3 mx-md-2">Events</a>
							</Link> */}
                <Link href="/resources">
                  <p className="app-menu-item m3-lg-3  py-2 mx-md-2">
                    Resources
                  </p>
                </Link>
                {/*<Link href="/communities">
								<p className="app-menu-item mx-lg-3 mx-md-2 py-2 hover-class-2">Communities</p>
							</Link>*/}
                <Link href="/usecases">
                  <p className="app-menu-item mx-lg-3 mx-md-2  py-2">
                    Use Cases
                  </p>
                </Link>
                <Link href="/pricing">
                  <p className="app-menu-item mx-lg-3 mx-md-2  py-2">Pricing</p>
                </Link>
                <a
                  href={`${hostEventUrl}/create/event`}
                  className="text-decoration-none"
                >
                  <p className="app-menu-item host-event mx-lg-3 px-3 mx-md-2 py-2 hover-class">
                    Host Event
                  </p>
                </a>
              </div>
            )}
            <div className="app-menu d-flex dropdown-user">
              {authContext.signedIn === null ? (
                <Loading className={"m-auto"} color="#fb5850" />
              ) : authContext.signedIn === true ? (
                <div className=" d-flex justify-content-center align-items-center align-items-lg-baseline ms-3">
                  {!eventPage && (
                    <div className="me-4">
                      <SwitchApplications />
                    </div>
                  )}
                  <ProfileCard
                    data={user}
                    onLogout={(e) => handleLogout(e)}
                    hideProfile={hideProfile}
                  />
                  {/* <span><i
											className="far fa-user"
											id="dropdownMenuButton1"
											data-bs-toggle="dropdown"
											aria-expanded="false"
										></i></span> */}

                  {/* <ul
											className="dropdown-menu dropdown-menu-position "
											aria-labelledby="dropdownMenuButton1"
										>
											<div  className="app-dropdown-menu-arrow-wrapper bg-dark dropdown-menu-arrow-wrapper">
												<span className="app-dropdown-menu-arrow dropdown-menu-arrow"></span>
											</div>
											<Link href={'/profile'} >
											<li className="dropdown-item">Manage Profile</li>
											</Link>
											<Link href={`${hostEventUrl}`} >
											<li className="dropdown-item">Dashboard</li>
											</Link>
											<Link href={`${hostEventUrl}/events/create`} >
											<li className="dropdown-item">
												Host Event
											</li>
											</Link>
											<li
												className="dropdown-item"
												onClick={(e) => handleLogout(e)}
											>
												Logout
											</li>
										</ul> */}
                </div>
              ) : (
                <div className="d-flex header-menu-item">
                  <p
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  ></p>
                  &nbsp;
                  <p
                    onClick={() => {
                      router.push(
                        router.asPath === "/"
                          ? loginUrl
                          : loginUrl + `/login?redirectToRoute=${router.asPath}`
                      );
                      // setloginModalOpen(true)
                    }}
                    className="link-text far fa-user "
                  ></p>
                  {/*

                  <p
                    onClick={() => {
                      router.push(
                        router.asPath === "/"
                          ? `${loginUrl}/signup`
                          : `${loginUrl}/signup?redirectToRoute=${router.asPath}`
                      );
                      // setSignupModalOpen(true)
                    }}
                    className="d-none d-md-inline-block  link-text"
                  >
                    &nbsp;/&nbsp;Signup
                  </p>
                   */}
                  {/* <ul
											className="dropdown-menu dropdown-menu-position"
											aria-labelledby="dropdownMenuButton1"
										>
											<div className="app-dropdown-menu-arrow-wrapper dropdown-menu-arrow-wrapper">
												<span className="app-dropdown-menu-arrow dropdown-menu-arrow"></span>
											</div>
											<li
												className="dropdown-item"
												onClick={() => }
											>
												Login
											</li>
											<li
												className="dropdown-item"
												onClick={() => setSignupModalOpen(true)}
											>
												Signup
											</li>
										</ul> */}
                </div>
              )}
            </div>
          </div>
        </div>
      </Container>

      {/* Mobile Navbar */}
      <MobileNavbar mobileNavbar={mobileNavbar} />

      <LoginModal
        newUserLogin={newUserLogin}
        modalValue={loginModalOpen}
        modalCloseFunction={setloginModalOpen}
        setSignupModalOpen={setSignupModalOpen}
        setPasswordModalOpen={setForgotPasswordModalOpen}
      />
      <SignupModal
        modalValue={signupModalOpen}
        modalCloseFunction={setSignupModalOpen}
        setloginModalOpen={setloginModalOpen}
      />

      <ForgotPasswordModal
        modalValue={forgotPasswordModalOpen}
        modalCloseFunction={setForgotPasswordModalOpen}
        setloginModalOpen={setloginModalOpen}
        setNewPasswordModal={setNewPasswordModal}
        email={email}
        setEmail={setEmail}
      />

      <NewPassowrdModal
        modalValue={newPasswordModal}
        modalCloseFunction={setNewPasswordModal}
        setloginModalOpen={setloginModalOpen}
        email={email}
      />
      <style jsx>{`
        .header-menu-item {
          color: ${color || "#572148"};
          font-size: 16px;
          text-decoration: none;
        }
        .link-text {
          cursor: pointer;
        }
        .app-menu .app-menu-item {
          font-weight: normal;
          font-size: 18px;
          line-height: 27px;
          color: ${color || " #572148"};
          text-decoration: none;
          cursor: pointer;
        }
        .app-menu .app-meu-item:hover {
          color: #fb5850 !important;
        }
        .dropdown-menu.show {
          inset: unset !important;
        }
        .dropdown-menu > li {
          margin: 0px !important;
        }
        .app-menu .host-event {
          font-size: 16px;
          line-height: 24px;
          color: #fb5850;
          border: 2px solid #fb5850;
          text-decoration: none;
        }

        .app-menu .user {
          font-size: 20px;
        }

        .app-menu .app-menu-item:hover {
          font-weight: 400;
        }

        .nav-buttons {
          color: #fff;
          text-decoration: none;
          z-index: 99;
        }

        .nav-buttons-active {
          color: #fb5850;
        }

        .dropdown-user {
          margin-top: 5px;
          display: flex !important;
          height: 100%;
          justify-content: center;
          align-items: center;
        }

        .hover-class {
          transition: 0.3s all;
        }

        .hover-class:hover {
          color: #ffffff !important;
          background: #fb5850;
        }

        .hover-class {
          transition: 0.3s all;
        }

        .hover-class-2:hover {
          color: #000000 !important;
        }
      `}</style>
    </>
  );
};

export default UnauthenticatedNavigation;