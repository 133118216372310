import { useState } from "react"

/**
 * 
 * @param {React.InputHTMLAttributes} props are added to input 
 */
export default function PasswordInput(props) {


    const [viewMode, setViewMode] = useState(false)


    return <div className={`passwordbox`} >
        <i className={viewMode ? 'fa fa-eye-slash' : 'fa fa-eye'} onClick={() => setViewMode(!viewMode)} ></i>
        <input type={viewMode ? 'text' : 'password'}  {...props} />
        <style>
            {
                `
                    .passwordbox{
                        position:relative;

                    }
                    .passwordbox > i {
                        position:absolute;
                        top:30%;
                        right:10px;
                        font-size:large;
                        cursor:pointer;
                        color:#fb5850;
                    }
                    `
            }
        </style>
    </div>
}