import React from 'react'
import Modal from "react-modal";
import NewPassword from "../NewPassword"

const NewPassowrdModal = ({ modalValue, modalCloseFunction, setloginModalOpen, email }) => {
	return (
		<Modal
			isOpen={modalValue}
			onRequestClose={() => modalCloseFunction(false)}
			className="react-modal"
		>
			<NewPassword
				setloginModalOpen={setloginModalOpen}
				modalCloseFunction={modalCloseFunction}
				email={email} />
		</Modal>
	)
}

export default NewPassowrdModal
