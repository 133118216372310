import React, { Fragment, useContext, useState } from "react";
import AuthContext from "../contexts/AuthContext";
import { Auth } from "aws-amplify";
import Cookies from "js-cookie";
import { ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";
import { errorToaster, successToaster } from "../helpers/utils";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import PasswordInput from "./Inputs/PasswordInput";
import { setJwtToken } from "../crud/axios";
import { useRouter } from "next/router";

const Login = ({
	newUserLogin,
	setSignupModalOpen,
	modalCloseFunction,
	setPasswordModalOpen,
}) => {
	const router = useRouter();
	const [loading, setLoading] = useState(false)
	const authContext = useContext(AuthContext);
	const [userNotConfirmed, setUserNotConfirmed] = useState(false);
	const [sendingMail, setSendingMail] = useState(false)
	const formik = useFormik({
		initialValues: { username: "", password: "" },
		validateOnChange: false,
		validationSchema:
			Yup.object({
				username: Yup.string().email("Invalid email address").required("Please enter your email"),
				password: Yup.string().required('Please enter a valid password')
			}),
		onSubmit: (values) => doLogin(values)
	})
	const doLogin = async ({ username, password }) => {
		try {
			setLoading(true);
			const res = await Auth.signIn(username, password);
			// Cookies.set("showSuggestions", true, {
			// 	expires: 365,
			// 	sameSite: "strict",
			// });
			// Cookies.set("authToken", res.signInUserSession.idToken.jwtToken, {
			// 	expires: 365,
			// 	sameSite: "strict",
			// });
			setJwtToken(res.signInUserSession.idToken.jwtToken);
			authContext.setAuth(res, true);
			let message = "Signed in successfully! Welcome back!!";
			successToaster(message);
			if (newUserLogin) router.replace('/', undefined, { shallow: true })
			modalCloseFunction(false);
			setLoading(false)
		} catch (error) {
			setLoading(false);
			if (error.message === "User is not confirmed.") {
				setUserNotConfirmed(true)
			}
			errorToaster(error.message);
		}
	};

	const doFederatedSignIn = async (provider) => {
		await Auth.federatedSignIn({ provider })
	}


	const resendConfirmationLink = async (e) => {
		e.preventDefault();
		setSendingMail(true);

		let error = '';
		if (formik.values.username === undefined || formik.values.username === "")
			error = "Please enter your email";
		if (!/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(formik.values.username))
			error = "Please enter a valid email address";

		if (error === '') {
			try {
				await Auth.resendSignUp(formik.values.username);
				successToaster('Verification email resent successfully.Please check the mail and verify your account before login.')

				setUserNotConfirmed(false);
			} catch (err) {
				let error = err.message || 'Something went wrong!';
				errorToaster(error);
			}
		} else {
			errorToaster(error);
		}
		setSendingMail(false)
	}




	return (
		<div className="form-wrap col-12">
			<form onSubmit={formik.handleSubmit}>
				<div className="card login_card">
					<div className="card-body ">
						<div className="container mt-4 p-0 px-md-5 px-lg-5">
							<span className="login_placeholder"> Login </span>
							<div className="row">
								<div>
									<div className="col-md-12  my-3">
										<input
											type="email"
											className="form-control"
											placeholder="Your Email"
											name="username"
											autoComplete="off"
											onChange={formik.handleChange}
											value={formik.values.username}
											onBlur={formik.handleBlur}
										/>
										{(formik.touched.username && formik.errors.username) && (<small className="text-danger" >{formik.errors.username}</small>)}
									</div>
								</div>
								<div>
									<div className="mb-3">
										<PasswordInput
											className="form-control"
											placeholder="Password"
											name="password"
											onChange={formik.handleChange}
											value={formik.values.password}
											onBlur={formik.handleBlur} />
										{(formik.touched.password && formik.errors.password) && (<small className="text-danger" >{formik.errors.password}</small>)}
									</div>
								</div>
								<div className="d-flex flex-column-reverse flex-md-row  justify-content-between col-md-12 col-12" >
									<div className=" align_center">
										<span
											className="forget_password signup"
											onClick={() => {
												setPasswordModalOpen(true);
												modalCloseFunction(false);
											}}
										>
											Forgot Password?
										</span>
									</div>
									{!loading && <button type="submit" className="  btn btn-theme text-center mb-3 my-md-0 my-lg-0">Login</button>}
									{loading && (<div className="spinner-border ms-auto  loading-btn" role="status"></div>)}
								</div>
								{/* <div className="col-12 text-center my-4">
									<span className="or_placeholder">
										<span className="or_text"> OR </span>
									</span>
								</div>
								<div className="text-center my-2 text-muted">
									Login with
								</div>
								<div className="col-12 text-center">
									<button type="button" onClick={async () => doFederatedSignIn(CognitoHostedUIIdentityProvider.Facebook)} className="btn m-1 google_btn fb_btn">
										<img src="/img/Facebook.svg" alt="Facebook Logo" />{" "}
									</button>
									<button type="button" onClick={async () => doFederatedSignIn(CognitoHostedUIIdentityProvider.Google)} className="btn m-1 google_btn">
										<img src="/img/google.svg" alt="Google Logo" />
									</button>
									<button type="button" onClick={async () => doFederatedSignIn(CognitoHostedUIIdentityProvider.Amazon)} className="btn m-1 amzn_btn">
										<img src="/img/amazon.svg" alt="Google Logo" />
									</button>
								</div> */}

								{

									userNotConfirmed && (sendingMail ? <div className="alert alert-info text-center my-4" role="alert">
										<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Sending confirmation mail.
                    			</div>
										:
										<div className="alert alert-info text-center my-4" role="alert">
											Looks like your account is not confimed. <a href="#" onClick={resendConfirmationLink}>Click Here</a> to re-send confirmation link.
										</div>
									)

								}

								<div className="col-md-12 my-4 text-center ">
									<span className="login_helper_text">
										Don&apos;t have an account?&nbsp;
										<span
											className="signup"
											onClick={() => {
												setSignupModalOpen(true);
												modalCloseFunction(false);
											}}
										>
											Sign-up
										</span>
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>

			<style jsx>{`
        .login_card {
          background: url("/img/login.svg") left top no-repeat;
          border: none;
          border-radius: 0;
        }
        .login_placeholder {
          font-family: Caveat Brush;
          font-size: 92px;
          line-height: 116px;
          color: #fb5850;
          display: block;
        }
        .login_helper_text {
          display: block;
          color: #572148;
          font-family: Prompt;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
        }
        .signup {
          text-decoration: underline;
          text-decoration-color: rgba(251, 88, 80, 0.25);
          text-decoration-thickness: 0.25rem;
          cursor: pointer;
        }

        .forget_password {
          font-family: Caveat Brush;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 23px;
          color: #000000;
        }

        .align_center {
          display: flex;
          align-items: center;
        }
        .or_text {
          color: #572148;
          font-family: Nunito;
          font-size: 13.0286px;
          line-height: 150%;
          background: #dddddd;
          border-radius: 50%;
          padding: 8px;
          position: absolute;
          top: -15px;
          right: -24px;
        }
        .or_placeholder {
          position: relative;
        }

        .or_hr {
          margin: 0 15%;
          height: 3px;
        }

        .google_btn {
          font-weight: 500;
          font-size: 18px;
          color: rgba(0, 0, 0, 0.54);
        }

        .fb_btn {
          background: #1877f2;
          color: #ffffff;
        }
		.amzn_btn{
		  background: #222e3d;
          color: #ffffff;
		}
	
		.form-btn{
			border:none !important;
			outline:none !important;
			box-shadow:none  !important;
		}
		
		.loading-btn{
			color:#fb5850 !important;
			text-align:rigt !important;
		}
		.form-wrap{
			overflow-y:auto;
			scrollbar-color:red;
		}
		::-webkit-scrollbar {
			width: 5px;
			height: 15px;
		  }
		
		  
		  ::-webkit-scrollbar-track-piece {
			background-color:transparent;
		  }
		  
		  ::-webkit-scrollbar-thumb:vertical {
			height: 30px;
			background-color: #572148;
		  }

      `}</style>
		</div>
	);
};

export default Login;
