import React from 'react'
import Modal from "react-modal";
import SignupCard from "../Signup"

const SignupModal = ({modalValue, modalCloseFunction, setloginModalOpen}) => {
	return (
		<Modal
			isOpen={modalValue}
			onRequestClose={() => modalCloseFunction(false)}
			className="react-modal"
		>
			<SignupCard setloginModalOpen={setloginModalOpen} modalCloseFunction={modalCloseFunction} />
			<style>
				{
					`
					.react-modal{
						display:flex !important;
						overflow:hidden !important;
					}
					`
				}

			</style>  
		</Modal>
	)
}

export default SignupModal
