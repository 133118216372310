import React from "react";
import Modal from "react-modal";
import LoginCard from "../Login";

const LoginModal = ({
	newUserLogin,
	modalValue,
	modalCloseFunction,
	setSignupModalOpen,
	setPasswordModalOpen
}) => {
	return (
		<Modal
			isOpen={modalValue}
			onRequestClose={() => modalCloseFunction(false)}
			className="react-modal"
		>
			<LoginCard
				newUserLogin={newUserLogin}
				setSignupModalOpen={setSignupModalOpen}
				modalCloseFunction={modalCloseFunction}
				setPasswordModalOpen={setPasswordModalOpen}
			/>
				<style>
				{
					`
					.react-modal{
						display:flex !important;
						overflow:hidden !important;
					}
					`
				}

			</style>  
		</Modal>
	);
};

export default LoginModal;
