import React, { Fragment } from "react";
import { Auth } from "aws-amplify";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { errorToaster, successToaster } from "../helpers/utils";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
toast.configure();

const ForgetPassword = ({
	modalCloseFunction,
	setloginModalOpen,
	email,
	setEmail,
	setNewPassword,
}) => {
	const [loading, setLoading] = React.useState(false)
	const formik = useFormik({
		initialValues: { email },
		validationSchema:
			Yup.object({
				email: Yup.string().email("Invalid email address").required("Please enter your email"),
			})
		,
		onSubmit: (value) => changePassword(value)
	})
	const changePassword = async ({ email }) => {
		setEmail(email);
		try {
			setLoading(true)
			await Auth.forgotPassword(email);
			successToaster('Sent a verification code to your email!')
			setNewPassword(true);
			modalCloseFunction(false);
		} catch (err) {
			let error = err.message || "Something went wrong!";
			errorToaster(error);
			setLoading(false)
		}
	};
	return (
		<Fragment>
			<form onSubmit={formik.handleSubmit} >
				<div className="card reset_card">
					<div className="card-body">
						<div className="container px-5">
							<div className="row">
								<div className="col-md-12 mt-4">
									<span className="reset_placeholder"> Reset Password </span>
								</div>
							</div>
							<div className="row mt-3">
								<div className="col-md-12">
									<div className="mb-3">
										<input
											name="email"
											type="email"
											className="form-control"
											placeholder="Your Email"
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.email}
										/>
										{formik.errors.email && <small className="text-danger" >{formik.errors.email}</small>}
									</div>
								</div>

								<div className="d-flex justify-content-end" >
									{!loading && <button type="submit" className="btn btn-theme ml-auto  text-end">
										Verify
									</button>}
									{loading &&
										(
											<div className="spinner-border ms-auto  loading-btn" role="status">
											</div>
										)
									}
								</div>
								{/* <div className="col-12 text-center my-4">
									<span className="or_placeholder">
										 <span className="or_text"> OR </span>
									</span>
								</div>
								<div className="text-center my-2 text-muted">
									Login with
								</div>
								<div className="col-12 text-center">
									<button type="button" onClick={
										async () => await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Facebook })} className="btn m-1 google_btn fb_btn">
										<img src="/img/Facebook.svg" alt="Facebook Logo" />{" "}
									</button>
									<button type="button" onClick={async () => await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google })} className="btn m-1 google_btn">
										<img src="/img/google.svg" alt="Google Logo" />
									</button>
									<button type="button" onClick={async () => await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Amazon })} className="btn m-1 amzn_btn">
										<img src="/img/amazon.svg" alt="Google Logo" />
									</button>
								</div> */}

							</div>
							<div className="col-md-12 mt-3 text-center ">
								<span className="reset_helper_text">
									Try signing-in again?
										<span
										className="signup"
										onClick={() => {
											modalCloseFunction(false);
											setloginModalOpen(true);
										}}
									>

										Sign-in
										</span>
								</span>
							</div>
						</div>
					</div>
				</div>
			</form>
			<style jsx>{`
        .reset_card {
          border-radius: 40px;
          background: url("/img/login.svg") left top no-repeat;
          border: none;
          border-radius: 0;
        }
        .reset_placeholder {
          font-family: Caveat Brush;
          font-size: 72px;
          line-height: 92px;
          color: #fb5850;
          display: block;
        }
        .reset_helper_text {
          display: block;
          color: #572148;
          font-family: Prompt;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
        }
        .signup {
          text-decoration: underline;
          text-decoration-color: rgba(251, 88, 80, 0.25);
          text-decoration-thickness: 0.25rem;
          cursor: pointer;
        }

        .forget_password {
          font-family: Caveat Brush;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 23px;
          color: #000000;
        }

        .align_center {
          display: flex;
          align-items: center;
        }
		.amzn_btn{
			background: #222e3d;
			color: #ffffff;
		  }

        .or_text {
          color: #572148;
          font-family: Nunito;
          font-size: 13.0286px;
          line-height: 150%;
          background: #dddddd;
          border-radius: 50%;
          padding: 8px;
          position: absolute;
          top: -15px;
          right: -24px;
        }

        .or_placeholder {
          position: relative;
        }

        .or_hr {
          margin: 0 15%;
          height: 3px;
        }

        .google_btn {
          font-weight: 500;
          font-size: 18px;
          color: rgba(0, 0, 0, 0.54);
        }

        .fb_btn {
          background: #1877f2;
          color: #ffffff;
        }
		.form-btn{
			background:transparent !important;
			border:none !important;
			outline:none !important;
			box-shadow:none  !important;
		}
		
		.loading-btn{
			color:#fb5850 !important;
			text-align:rigt !important;
		}
      `}</style>
		</Fragment>
	);
};

export default ForgetPassword;
