import React from "react";
import Modal from "react-modal";
import ForgetPassword from "../ForgetPassword";

const ForgotPasswordModal = ({
	modalValue,
	modalCloseFunction,
	setloginModalOpen,
	email,
	setEmail,
	setNewPasswordModal,
}) => {
	return (
		<Modal
			isOpen={modalValue}
			onRequestClose={() => modalCloseFunction(false)}
			className="react-modal"
		>
			<ForgetPassword
				setloginModalOpen={setloginModalOpen}
				modalCloseFunction={modalCloseFunction}
				setEmail={setEmail}
				setNewPassword={setNewPasswordModal}
				email={email}
			/>
		</Modal>
	);
};

export default ForgotPasswordModal;
