import React, { useState, Fragment } from "react";
import { Auth } from "aws-amplify";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { errorToaster, successToaster } from "../helpers/utils";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import Cookies from "js-cookie";
import PasswordInput from "./Inputs/PasswordInput";
import Reaptcha from 'reaptcha';
import { baseUrl, mediaUrl } from "../config/config";

toast.configure();

const Signup = ({ modalCloseFunction, setloginModalOpen, }) => {
	const [loading, setLoading] = useState(false)
	const [verified, setVerified] = useState(false)
	const formik = useFormik({
		initialValues: { username: '', password: '' },
		validationSchema:
			Yup.object({
				// name: Yup.string().required('Please enter your name'),
				username: Yup.string().email('Invalid email address').required('Please enter your email'),
				password: Yup.string().required("Please enter a valid password").min(6, "Must be at least 6 characters").matches(
					/^(?=.*[a-z])(?=.*[A-Z])/, "Must Contain  One Uppercase Character").matches(/[0-9]/, "Must Contain One Number"),
				confirmPassword: Yup.string().required('Please confirm the password').oneOf([Yup.ref('password'), null], 'Passwords must match')
			})
		,
		onSubmit: (values) => doSignup(values)
	})
	const doSignup = async ({ name, username, password }) => {
		try {
			setLoading(true);
			await Auth.signUp({
				username,
				password,
				attributes: {
					picture: `${mediaUrl}/default-profile.png`,
					profile: 0
				},
			});
			let message =
				"Your account has been created.Please check the mail and verify your account before login.";
			successToaster(message)
			modalCloseFunction(false)
			setloginModalOpen(true)
		} catch (error) {
			setLoading(false);
			errorToaster(error.message);
		}
	};

	const doFederatedSignIn = async (provider) => {
		try {
			const res = await Auth.federatedSignIn({ provider })
		} catch (error) {
			errorToaster(error.message);
		}
	}


	return (
		<div className="form-wrap" >
			<form onSubmit={formik.handleSubmit} >
				<div className="card signup_card">
					<div className="card-body">
						<div className="container px-5">
							<div className="row">
								<div className="col-md-12 mt-4">
									<span className="signup_placeholder"> Signup </span>
								</div>
							</div>
							<div className="row mt-3">
								<div className="col-md-12">
									{/* <div className="mb-3">
										<input
											type="text"
											name="name"
											className="form-control"
											placeholder="Name"
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.name}
										/>
										{ (formik.touched.name && formik.errors.name) && (<small className="text-danger" >{formik.errors.name}</small>)}
									</div> */}
									<div className="mb-3">
										<input
											type="email"
											name="username"
											className="form-control"
											placeholder="Your Email"
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.username}
										/>
										{(formik.touched.username && formik.errors.username) && (<small className="text-danger" >{formik.errors.username}</small>)}
									</div>
								</div>
								<div>
									<div className="mb-3">
										<PasswordInput
											className="form-control"
											placeholder="Password"
											name="password"
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.password}
										/>
										{(formik.touched.password && formik.errors.password) && (<small className="text-danger" >{formik.errors.password}</small>)}
									</div>
								</div>
								<div>
									<div>
										<PasswordInput
											className="form-control"
											placeholder="Confirm Password"
											name="confirmPassword"
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.confirmPassword}
										/>
										{(formik.touched.confirmPassword && formik.errors.confirmPassword) && (<small className="text-danger" >{formik.errors.confirmPassword}</small>)}
									</div>
								</div>
								<div className="col-md-4 col-sm-12 mt-3">
									<Reaptcha sitekey="6LeOkfggAAAAAJjHLau9WKP5cHRR8YGyFJ8Zj9wD" onVerify={() => setVerified(true)} />
								</div>
								<div className="mt-3" style={{fontSize: "14px"}}>
									<label htmlFor="Consent">
									<div className="mt-3" style={{fontSize: "14px"}}>
									<label htmlFor="Consent">
										By signing up, I agree to the <a href={`${baseUrl}/privacy-policy`} target="_blank">Privacy Policy</a> and the <a href={`${baseUrl}/terms-and-conditions`} target="_blank">Terms and Conditions</a>
									</label>
								</div>
									</label>
								</div>
								<div className="d-flex justify-content-end" >
									{!loading && <button type="submit" disabled={!verified} className="col-md-5 col-12 col-xs-3 my-3 btn btn-theme ml-auto">
										Signup
									</button>}
									{loading &&
										(
											<div className="spinner-border ms-auto  my-3 loading-btn" role="status">
											</div>
										)
									}
								</div>
								{/* <div className="col-12 text-center my-4">
									<span className="or_placeholder">
										 <span className="or_text"> OR </span>
									</span>
								</div>
								<div className="text-center my-2 text-muted">
									Login with
								</div>
								<div className="col-12 text-center">
									<button type="button" onClick={
										async () => await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Facebook })} className="btn m-1 google_btn fb_btn">
										<img src="/img/Facebook.svg" alt="Facebook Logo" />{" "}
									</button>
									<button type="button" onClick={async () => await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google })} className="btn m-1 google_btn">
										<img src="/img/google.svg" alt="Google Logo" />
									</button>
									<button type="button" onClick={async () => await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Amazon })} className="btn m-1 amzn_btn">
										<img src="/img/amazon.svg" alt="Google Logo" />
									</button>
								</div> */}
								<div className="col-md-12  mb-2 text-center my-md-4 mb-5 my-4">
									<span className="signup_helper_text">
										Already have an account? {" "}
										<span
											className="signup"
											onClick={() => {
												setloginModalOpen(true);
												modalCloseFunction(false);
											}}
										>
											Sign-in
										</span>
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>

			<style jsx>{`
        .signup_card {
          border-radius: 40px;
          background: url("/img/login.svg") left top no-repeat;
          border: none;
          border-radius: 0;
        }
        .signup_placeholder {
          font-family: Caveat Brush;
          font-size: 92px;
          line-height: 116px;
          color: #fb5850;
          display: block;
        }
        .signup_helper_text {
          display: block;
          color: #572148;
          font-family: Prompt;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
        }
        .signup {
          text-decoration: underline;
          text-decoration-color: rgba(251, 88, 80, 0.25);
          text-decoration-thickness: 0.25rem;
          cursor: pointer;
        }

        .forget_password {
          font-family: Caveat Brush;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 23px;
          color: #000000;
        }

        .align_center {
          display: flex;
          align-items: center;
        }

        .or_text {
          color: #572148;
          font-family: Nunito;
          font-size: 13.0286px;
          line-height: 150%;
          background: #dddddd;
          border-radius: 50%;
          padding: 8px;
          position: absolute;
          top: -15px;
          right: -24px;
        }

        .or_placeholder {
          position: relative;
        }
		input{
			background:#fff;
		}
        .or_hr {
          margin: 0 15%;
          height: 3px;
        }

        .google_btn {
          font-weight: 500;
          font-size: 18px;
          color: rgba(0, 0, 0, 0.54);
        }
		.amzn_btn{
			background: #222e3d;
			color: #ffffff;
		  }
        .fb_btn {
          background: #1877f2;
          color: #ffffff;
        }
		.form-btn{
			background:transparent !important;
			border:none !important;
			outline:none !important;
			box-shadow:none  !important;
		}
		
		.loading-btn{
			color:#fb5850 !important;
			text-align:rigt !important;
		}
		.form-wrap{
			overflow-y:scroll;
			scrollbar-color:red;
		}
		::-webkit-scrollbar {
			width: 5px;
			height: 15px;
		  }
		
		  
		  ::-webkit-scrollbar-track-piece {
			background-color:transparent;
		  }
		  
		  ::-webkit-scrollbar-thumb:vertical {
			height: 30px;
			background-color: #572148;
		  }
      `}</style>
		</div>
	);
};

export default Signup;
